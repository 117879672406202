@import '../../../app/globalStyles/color-variables';
@import '../../../app/globalStyles/mixins';

.logout-button {
  @include unstyledButton;

  align-items: center;
  background-color: $dark-gray;
  color: $off-white;
  display: flex;
  flex-direction: row;
  font-size: 1em;
  justify-content: flex-end;
  padding: 20px;

  i {
    font-size: 2em;
    margin-inline-end: 10px;
  }
}
