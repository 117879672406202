@import 'color-variables';

// display: none is an a11y problem
// this mixin visually hides an element without making it inaccessible to assistive devices
@mixin accessible-display-none {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: -1;
}

@mixin box-shadow {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}

@mixin flex-container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

@mixin table-header {
  background-color: $off-white;
  border: none;
  border-bottom: 2px solid $dark-gray;
  color: $gray;
  font-weight: 700;
  text-align: start;
  text-transform: uppercase;
}

@mixin table-row {
  background-color: $white;
  border: none;
  border-bottom: 1px solid $light-gray;
  color: $black;
}
@mixin table-in-card {
  @media screen and (max-width: 680px) {
    .optional-access {
      @include accessible-display-none;
    }
  }
  .p-datatable-wrapper {
    table {
      tbody .p-datatable-row {
        word-break: break-word;
        td .p-column-title {
          min-width: 40%;
          width: 40%;
        }
      }
    }
  }
}

@mixin responsive-table {
  // Forces a table to render responsively
  .cell:first-of-type {
    border-top: 1px solid $light-gray;
  }

  // This behavior exists in the primereact css file.  The rule against display none
  // will be turned off to replicate the original behavior.  The content in this element
  // is duplicated elsewhere in the UI so that a screen reader will still be able to access the content.
  /* stylelint-disable */
  .p-datatable-responsive .p-datatable-thead > tr > th,
  .p-datatable-responsive .p-datatable-tfoot > tr > td {
    display: none !important;
  }

  .p-datatable-responsive .p-datatable-tbody > tr > td {
    /* stylelint-enable */
    border: 0 none;
    clear: inline-start;
    display: inline-flex; // Originally block
    flex-direction: row; // Added
    float: inline-start;
    text-align: start;
    width: 100% !important;

    & .p-column-title {
      color: $gray;
      display: inline-block;
      font-weight: bold;
      margin: -0.4em 1em -0.4em -0.4em;
      min-width: 16em; // Originally 30%
      padding: 0.4em;
    }
  }
}

@mixin unstyledButton {
  align-items: flex-start;
  background-color: unset;
  border-color: unset;
  border-image: unset;
  border-style: unset;
  border-width: unset;
  box-sizing: border-box;
  color: unset;
  cursor: default;
  display: inline-block;
  font: unset;
  letter-spacing: normal;
  margin: unset;
  padding: unset;
  text-align: center;
  text-indent: 0;
  text-rendering: auto;
  text-shadow: none;
  text-transform: none;
  word-spacing: normal;
}

@mixin disabled-button {
  background-color: $medium-gray;
  color: $dark-gray;
  cursor: not-allowed;
}

@mixin grayWrapperWithPadding {
  background-color: $light-gray;
  color: $black;
  padding: 20px 40px;
}
