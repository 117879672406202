@import '../../app/globalStyles/color-variables';

$closedContainerHeight: 250px;
$animationDuration: 0.5s;

.branch-card-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: visible;
  overflow-y: scroll;
  padding: 10px;
  position: absolute;
  width: 400px;
  // /* The Leaflet map utilizes z-indices below 1000*/
  z-index: 1001;

  @media screen and (max-width: 680px) {
    padding-block-end: 40px;
    width: 100%;

    &.open {
      overflow-y: scroll;
    }

    &.closed {
      // in the future, we should replace `bottom` with `inset-block-end`;
      // however, this is currently still an experimental property and cannot reliably be used in production
      /* stylelint-disable-next-line */
      bottom: 0;
      height: $closedContainerHeight;
      overflow-y: hidden;
    }
  }
}

.branch-card-container-shield {
  background-color: $off-white;
  /* stylelint-disable  */
  // Ideally here we would be using inset.
  // However this is currently still an experimental property.
  // Therefore we can't reliably use it.
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  /* stylelint-enable */
  z-index: 0;

  &.open {
    animation: none;
    opacity: 1;
  }

  &.closed {
    animation: none;
    opacity: 0;
  }

  @media screen and (max-width: 680px) {
    &.open {
      animation: none;
      opacity: 1;
      z-index: 1001;
    }

    &.closed {
      animation: none;
      opacity: 0;
      z-index: 0;
    }
  }
}
