@import '../../../../app/globalStyles/_mixins';

.flex-container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 40px 0 40px;

  h2 {
    flex: 1 1 50%;
    line-height: 34px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.button-container {
  display: flex;
  flex: 0 0 450px;
  flex-direction: row;
  justify-content: flex-end;
}

.coordinates-do-not-exist-error {
  color: $red;
  margin: 20px 40px 0 40px;
}

// wrapper around re-upload and push-to-prod buttons
.spreadsheet-buttons {
  i {
    margin-inline-end: 5px;
  }
  button:last-of-type {
    margin-inline-start: 20px;
  }
}

.push-to-prod-button.disabled {
  @include disabled-button;
}

@media screen and (max-width: 980px) {
  .flex-container-row h2 {
    flex: 1 1 100%;
    margin-block-end: 10px;
  }

  .button-container {
    flex: 1 1 100%;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 765px) {
  .flex-container-row {
    margin: 20px;
  }
}

@media screen and (max-width: 530px) {
  .flex-container-row h2 {
    flex: 1 1 100%;
  }

  .button-container {
    flex: 1 1 100%;
    justify-content: flex-start;
  }
}
