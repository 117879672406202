@import '../../../app/globalStyles/color-variables';
@import '../../../app/globalStyles/typography-variables';

.user-message {
  align-items: center;
  box-shadow: 0 1px 4px 0 $gray;
  color: $white;
  display: flex;
  flex-direction: row;
  font-size: $base-font-size-px;
  font-weight: $base-font-weight-bold;
  justify-content: space-between;
  margin: 20px 40px 0 40px;
  padding: 10px 10px;

  .message-icon {
    font-size: 2.5em;
  }

  .line-start-block {
    align-items: center;
    display: flex;
    flex-direction: row;

    span {
      // ensure the there is enough space between the text of the message and the icons on either side
      margin-inline-end: 10px;
      margin-inline-start: 10px;
    }
  }

  &.error {
    background-color: $red;
  }

  &.warning {
    background-color: $yellow;
    color: $black;
  }

  &.success {
    background-color: $green;
  }

  &.info {
    background-color: $blue;
  }
}
