@import '../../app/globalStyles/_color-variables';
.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.branch-to-store-mapping-container {
  border-top: 2px solid $gray;
  flex: 1 1 0;
  margin-block-start: 1em;
  position: relative;

  ::-webkit-scrollbar {
    width: 0;
  }
}
