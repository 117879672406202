@import '../../app/globalStyles/_color-variables';
@import '../../app/globalStyles/_mixins';

.supplier-info-grid {
  &:not(:last-of-type) {
    padding-block-end: 20px;
  }
  @include table-in-card;

  // We need to have a very high level of specificity to overwrite PrimeReact's default styles
  .p-datatable-wrapper {
    table {
      border-collapse: collapse;
      table-layout: auto;
      width: 100%;
    }

    thead tr th {
      @include table-header;
    }

    tbody tr td {
      @include table-row;
    }

    .contact-cell,
    .title-cell,
    .qc-cell {
      width: 20%;
    }

    .email-cell {
      width: 15%;
    }

    .phone-cell {
      width: 170px;
    }

    .qc-cell-1 {
      width: 120px;
    }

    .number-cell {
      width: 135px;
    }

    @media screen and (max-width: 1400px) {
      .qc-cell {
        width: 190px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    @include responsive-table;

    tbody {
      border-block-start: 1px solid $medium-gray;

      tr {
        border-block-end: 1px solid $medium-gray;
      }
    }
  }
}
