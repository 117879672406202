@import '../../app/globalStyles/_color-variables';

.validation-display {
  padding: 0 40px 20px 40px;

  thead .p-column-title,
  tbody .p-column-title,
  tbody .location {
    text-transform: uppercase;
  }

  &.centered {
    text-align: center;
  }

  @media only screen and (max-width: 765px) {
    padding: 10px 20px;
  }
}

.validation-display-header {
  margin-inline-start: 5px;

  .header-text {
    color: $black;
    font-weight: 600;
    margin-inline-start: 7px;
  }

  .icon_report-filled {
    color: $red;
    font-size: 1.25em;
  }
}

// We need to have a very high level of specificity
// to overwrite the default styles of the component library
.validation-display-table-container .p-datatable-wrapper table {
  border-collapse: collapse;
  width: 100%;

  thead tr th {
    background-color: $white;
    border: none;
    border-bottom: 1px solid $black;

    &.row-number {
      width: 80px;
    }

    &.minor-cell {
      width: 15%;
    }
  }

  tbody tr {
    // This is the responsive media query used by the table
    // In order to get our lines to work right, we have to do this.
    @media screen and (max-width: 40em) {
      .cell:first-of-type {
        border-top: 1px solid $light-gray;
      }
    }

    td {
      background-color: $white;
      border: none;
      border-top: 1px solid $light-gray;

      &.cell {
        color: $black;

        &.bolded {
          font-weight: 700;
        }

        &.is-error {
          color: $red;
          font-weight: 600;
        }
      }
    }
  }

  .header-cell {
    font-weight: 700;
    text-align: start;
  }
}
