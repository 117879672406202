@import '../../app/globalStyles/_color-variables';
@import '../../app/globalStyles/typography-variables';
@import '../../app/globalStyles/mixins';

.header {
  $headerBorder: 4px;

  background-color: $black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h1 {
    color: $light-gray;
    display: inline;
    font-size: 1.5em;
    font-weight: $base-font-weight;
    margin-inline-start: 10px;
    vertical-align: middle;
  }

  button.header-block {
    @include unstyledButton;

    padding: 10px;
  }

  .header-block {
    align-items: center;
    border-bottom: $headerBorder solid $black;
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 10px;
  }

  .right-header-block {
    align-items: center;
    display: flex;
    flex-direction: row;

    .header-block {
      border-bottom: $headerBorder solid $black;
      color: $light-gray;
      font-size: 1em;
      font-weight: $base-font-weight;

      &:hover,
      &:focus {
        border-bottom: $headerBorder solid $orange;
      }
    }
  }

  .bolded-text {
    font-weight: $base-font-weight-bold;
  }

  img {
    height: 40px;
    vertical-align: middle;
  }

  span {
    i {
      margin-inline-start: 15px;
    }
  }

  @media only screen and (max-width: 675px) {
    .left-header-block {
      h1 {
        @include accessible-display-none;
      }
    }
  }
}
