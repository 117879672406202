@import '../../../../app/globalStyles/_color-variables';
@import '../../../../app/globalStyles/_mixins';

.progress-wrapper {
  @include grayWrapperWithPadding;

  &.success {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h2 {
      display: flex;
    }

    .icon_check {
      color: $orange;
      flex: 0 0 24px;
      margin-inline-end: 15px;
    }

    a {
      color: $orange;
      margin-block-end: 1.25em;
      margin-block-start: 1.25em;
      text-transform: uppercase;

      /* stylelint-disable  */
      // stylelint gives the following error:
      // unexpected using "outline" property in &:hover and &:focus
      // we do eliminate the border on :focus and :hover, but instead provide a border
      // to maintain accessibility
      &:hover,
      &:focus {
        border-bottom: 2px solid $orange;
        margin-block-end: 1.125em;
        outline: none;
      }
      /* stylelint-enable */
    }

    @media screen and (max-width: 700px) {
      flex-wrap: wrap;

      h2 {
        flex: 1 1 100%;
        margin-block-end: 0.125em;
        margin-block-start: 0.375em;
      }

      a {
        flex: 1 1 100%;
        margin-block-end: 0.25em;
        margin-block-start: 0.125em;
        margin-inline-start: 39px;
        max-width: 192px;

        &:hover,
        &:focus {
          margin-block-end: 0.125em;
        }
      }
    }
  }

  > .flex-row {
    display: flex;
    flex-direction: row;

    span {
      flex: 0 0 auto;
      line-height: 34px; // match to button height
      margin-inline-end: 20px;
    }

    .progress-bar {
      flex: 1 1 60%;
      margin: 14px 0;
    }

    button {
      flex: 0 0 100px;
      height: 34px;
      margin-inline-start: 20px;
    }
  }
}
