@import '../../../app/globalStyles/color-variables';
@import '../../../app/globalStyles/typography-variables';

.branch-to-store-card {
  background-color: $white;
  border-radius: 2px;
  box-shadow: 0 0 3px $gray;
  display: grid;
  font-size: 1em;
  grid-auto-rows: auto;
  // This warning can be ignored because all browsers we support allow this feature.
  grid-template-columns: 140px 1fr; /* stylelint-disable-line */
  padding: 20px;
  width: 100%;

  h4 {
    margin-block-end: 0;
    margin-block-start: 0;
  }

  span {
    color: $black;
  }

  &.active {
    box-shadow: 0 0 3px $orange;
    outline: 4px auto $orange;

    h4 {
      color: $dark-gray;
    }

    .branch-name,
    .address,
    .store-count {
      font-weight: $base-font-weight-bold;
    }
  }

  &:focus {
    box-shadow: 0 0 15px $orange;
    outline: none;

    &.active {
      outline: 4px auto $orange;
    }
  }
}

.card-wrapper {
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0;
  padding-block-end: 0;
  width: 100%;
}
