@import '../../../app/globalStyles/_color-variables';

.submission-display-container {
  background-color: $light-gray;
  margin: 20px 40px 0 40px;
  padding: 10px 20px;

  span {
    color: $black;
    margin-inline-end: 5px;
    position: relative;
  }

  .number {
    font-weight: 700;
    margin-inline-end: 50px;
    min-width: 5px;
  }

  // match spacing between the file input component at the top of the page
  @media only screen and (max-width: 765px) {
    margin: 10px 20px 0 20px;
  }

  @media only screen and (max-width: 630px) {
    display: grid;
    grid-template-columns: 175px 25px; /* stylelint-disable-line */
  }

  @media only screen and (max-width: 280px) {
    // inherits display: grid from the media query above
    grid-template-columns: 1fr; /* stylelint-disable-line */
  }
}
