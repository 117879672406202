@import '../../app/globalStyles/_color-variables';
@import '../../app/globalStyles/_mixins';

.file-input-wrapper {
  @include grayWrapperWithPadding;

  label {
    display: block;
  }

  @media only screen and (max-width: 765px) {
    padding: 10px 20px;
  }
}
