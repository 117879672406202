@import '../../app/globalStyles/_color-variables';

.progress-bar {
  background-color: $light-orange;
  display: flex;
  height: 5px;

  > div {
    background-color: $orange;
    // the width of the inner div is controlled by the percentComplete prop
    // via inline styles in the React component
  }
}
