.not-found {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

h1 {
  font-size: 8em;
}
