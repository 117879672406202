@import '../../app/globalStyles/_color-variables';
@import '../../app/globalStyles/_mixins';

// We need to have a very high level of specificity to overwrite PrimeReact's default styles
.branch-to-store-grid-mapping-container {
  @include table-in-card;

  .p-datatable-wrapper {
    thead tr th {
      @include table-header;
    }

    tbody tr td {
      @include table-row;
    }
  }

  .minor-cell {
    width: 80px;
  }

  @media screen and (max-width: 1200px) {
    @include responsive-table;

    tbody {
      border-block-start: 1px solid $medium-gray;

      tr {
        border-block-end: 1px solid $medium-gray;
      }
    }
  }
}
