/////////////////////////////////////////////////
// This file is for global, application-wide styles.
// These styles are imported into app.js, then applied to the html tag, body tag, and #root div as appropriate.
// You should NOT import the styles below into individual components or features.

// Root styles are baselines set against document level elements only.
// If your selector is not *, html, body, or :root it does not belong here.

// GLOBAL VARIABLES /////////////////////////////
@import 'color-variables';
@import 'typography-variables';

// WEB FONTS ////////////////////////////////////
// We load a pared-down version of Google's Open Sans font.
// To add support for bold italic, add `,600italic` to the end of the URL below.
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600');

// External style dependencies
//   - Prime React
@import '../../../node_modules/primereact/resources/themes/nova-light/theme.css';
@import '../../../node_modules/primereact/resources/primereact.min.css';

//   - QC UxIcons
@import '../../../node_modules/@quotecenter/uxicon/uxicon.css';

// ROOT /////////////////////////////////////////
html {
  font-size: $base-font-size-px;
}

body {
  color: $dark-gray;
  font-family: $base-font-family;
  font-size: $base-font-size-px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  -ms-font-smoothing: antialiased !important;
  font-style: normal;
  font-weight: $base-font-weight;
  line-height: $base-line-height-px;
  margin: 0;

  :focus {
    outline-color: $orange;
  }
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}
