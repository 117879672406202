/////////////////////////////////////////////////
// This file is for global typography variables definitions.
// To access a variable, import this file into your common component or feature's SCSS file:
// @import "../../app/globalStyles/typography-variables";

// If your code is anything other than a variable definition, it does not belong here.

// TYPOGRAPHY ///////////////////////////////////
// FONT FAMILY
$base-font-family: "Open Sans", sans-serif !default;

// FONT WEIGHT
$base-font-weight: 400;
$base-font-weight-italic: 400 italic;
$base-font-weight-bold: 600;
$base-font-weight-bold-italic: 600 italic;

// FONT SIZE
$base-font-size-px: 16px;
$base-line-height-px: 24px;
$relative-font-size-em: 0.85em;
