/////////////////////////////////////////////////
// This file is for global color variables definitions.
// To access a variable, import this file into your common component or feature's SCSS file:
// @import "../../app/globalStyles/color-variables";

// If your code is anything other than a variable definition, it does not belong here.
/* stylelint-disable color-no-hex  */

// PRIMARY COLORS ///////////////////////////////
// BLACK, WHITE & GRAY
$black: #000000;        // $color-black
$white: #FFFFFF;        // $color-white
$gray: #999999;         // $color-gray-prime
$off-white: #FBFBFB;    // $color-gray-01
$light-gray: #E5E5E5;   // $color-gray-04
$medium-gray: #CCCCCC;  // $color-gray-05
$dark-gray: #666666;    // $color-gray-06

// ORANGE
$orange: #F96302;
$light-orange: #FED4B9; // $color-orange-02
$dark-orange: #DB5702;  // $color-orange-04

// SECONDARY COLORS /////////////////////////////
$green: #00AE4D;        // $color-green-prime
$red: #ED1C24;          // $color-red-prime
$yellow: #FFC20E;       // $color-yellow-prime
$blue: #286FAD;         // $color-blue-prime
