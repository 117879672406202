@import '../../app/globalStyles/_color-variables';

.leaflet-container {
  height: 100%;
  width: 100%;
}

.icon-mapped {
  color: $orange;
}

.icon-unmapped {
  color: $gray;
}
