@import '../../app/globalStyles/_color-variables';
@import '../../app/globalStyles/_mixins';

.branch-display-table-container {
  @include table-in-card;

  .p-datatable-wrapper {
    thead tr th {
      @include table-header;
    }

    tbody tr td {
      @include table-row;
    }
  }

  .medium-cell {
    width: 170px;
  }

  @media screen and (max-width: 1200px) {
    @include responsive-table;

    tbody {
      border-block-start: 1px solid $medium-gray;

      tr {
        border-block-end: 1px solid $medium-gray;
      }
    }
  }
}
