@import '../../../app/globalStyles/_color-variables';
@import '../../../app/globalStyles/_mixins';

.toggle-view-button-container {
  margin-inline-end: 20px;

  i {
    font-size: 1.25em;
  }

  .selected {
    background-color: $light-gray;
    color: $dark-gray;
  }

  button.disabled {
    @include disabled-button;
  }
}
