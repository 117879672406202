@import "../../app/globalStyles/_color-variables";

.card {
    background-color: $off-white;
    border-color: $medium-gray;
    border-style: solid;
    border-width: 1px 1px 0 1px;
    display: inline-block;
    width: 100%;

    header {
        background-color: $white;
        border-bottom: 1px solid $medium-gray;
        color: $black;
        font-size: 1.125em;
        font-weight: 600;
        padding: 20px;
    }

    section {
        padding: 20px;
    }

    &:last-of-type {
        border-width: 1px;
    }
}
